import { useTranslation } from '@wix/yoshi-flow-editor';
import { classes } from './CartModal.st.css';
import React, { Suspense } from 'react';
import { InputDialog, Card } from 'wix-ui-tpa';
import Text, { TextType } from '../../Text/Text';
import {
  CartFlow,
  CartModalStatus,
  ReservedPaymentOptionIds,
} from '../../../../types/types';
import { useFormActions } from '../../Hooks/useFormActions';
import { Service } from '../../../../utils/mappers/service.mapper';
import { Slot } from '@wix/ambassador-availability-calendar/types';
import DateAndTime from '../DateAndTime/DateAndTime';
import { CartModalDataHooks } from './dataHooks';
import { Membership } from '@wix/ambassador-memberships-spi-host/http';

const ServiceImage = React.lazy(() => import('../ServiceImage/ServiceImage'));

export interface CartModalProps {
  cartModalStatus: CartModalStatus;
  service: Service;
  slot: Slot;
  dateRegionalSettingsLocale: string;
  selectedPaymentOptionId: string;
  cartLineItems: any[];
  eligibleMemberships?: Membership[];
}

const CartModal: React.FC<CartModalProps> = ({
  cartModalStatus,
  service,
  dateRegionalSettingsLocale,
  slot,
  selectedPaymentOptionId,
  cartLineItems,
  eligibleMemberships,
}) => {
  const { t } = useTranslation();
  const { onCartCTAClick } = useFormActions();
  const serviceImage = service.images?.[0];
  const isFreePrice =
    service?.payment?.paymentDetails?.isFree &&
    selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;
  const isCustomPrice =
    service?.payment?.paymentDetails?.priceText &&
    selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;
  const isMembershipOptionSelected = eligibleMemberships?.some(
    (membership) => selectedPaymentOptionId === membership.id,
  );

  const isSingleItemOnCart = cartLineItems?.length === 1;

  const shouldSecondaryButtonBeCheckout =
    isSingleItemOnCart &&
    (isFreePrice ||
      isCustomPrice ||
      isMembershipOptionSelected ||
      service.isPendingApprovalFlow);

  return (
    <InputDialog
      data-hook={CartModalDataHooks.INPUT_DIALOG}
      className={classes.root}
      title={t('app.booking-details.cart-modal.title')}
      isOpen={cartModalStatus === CartModalStatus.OPEN}
      onClose={() => onCartCTAClick(CartFlow.ADD_TO_CART)}
      primaryButton={{
        text: t('app.booking-details.cart-modal.cta.add-more-sessions.label'),
        onClick: async () => {
          await onCartCTAClick(CartFlow.ADD_TO_CART);
        },
      }}
      secondaryButton={{
        text: t(
          shouldSecondaryButtonBeCheckout
            ? 'app.booking-details.cart-modal.cta.complete-checkout.label'
            : 'app.booking-details.cart-modal.cta.checkout.label',
        ),
        onClick: async () => {
          await onCartCTAClick(CartFlow.CHECKOUT);
        },
      }}
    >
      <Card data-hook={CartModalDataHooks.CARD} className={classes.card}>
        {serviceImage && (
          <Suspense fallback={<div></div>}>
            <Card.Container className={classes.left}>
              <ServiceImage {...serviceImage} />
            </Card.Container>
          </Suspense>
        )}
        <Card.Container className={classes.right}>
          <div>
            <Text
              data-hook={CartModalDataHooks.CARD_TITLE}
              type={TextType.Primary}
            >
              {service.name}
            </Text>
            <DateAndTime
              totalNumberOfSessions={1}
              dateRegionalSettingsLocale={dateRegionalSettingsLocale}
              slot={slot}
              service={service}
            />
          </div>
        </Card.Container>
      </Card>
    </InputDialog>
  );
};

export default CartModal;
